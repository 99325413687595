<template>
  <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
    <b-col cols="12" class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden">
      <h2>List Pasien</h2>
      <div class="d-flex justify-content-between mb-3">
        <div class="row align-items-center">
          <p class="hp-p1-body mb-0 mx-3 text-black">
            Semua ({{ table.total }})
          </p>
          <router-link to="/dashboards/add-patient">
            <b-button variant="primary-2">
              <i class="ri-add-line" /> Tambah Pasien
            </b-button>
          </router-link>
        </div>
        <div class="row no-gutters w-15">
          <b-input-group>
            <b-input-group-prepend>
              <b-button
                variant="outline-black-50"
                class="border-right-0 pr-0 pl-2"
              >
                <i class="ri-search-line remix-icon text-primary" />
              </b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="table.search"
              type="search"
              placeholder="Search"
              class="border-left-0"
              @input="debounceSearch"
            >
            </b-form-input>
          </b-input-group>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex align-items-center mb-3">
          <label class="mr-3">Tanggal</label>
          <b-form-select
            class="mr-3"
            style="width: 90px"
            v-model="table.day"
            type="search"
            :options="dayOptions"
            @input="filterData"
            placeholder="Pilih Tanggal"
          />
          <label class="mr-3">Bulan</label>
          <b-form-select
            class="mr-3"
            style="width: 150px"
            v-model="table.month"
            :options="monthOptions"
            @input="filterData"
          />
          <label class="mr-3">Tahun</label>
          <b-form-select
            class="mr-3"
            style="width: 150px"
            v-model="table.year"
            :options="yearOptions"
            @input="filterData"
          />
        </div>
      </div>
    </b-col>
    <div class="col-12">
      <b-table
        style="overflow: auto; white-space: nowrap"
        :items="itemsFormatted"
        :fields="fields"
        :busy.sync="isBusy"
        responsive="sm"
      >
        <template v-slot:cell(Aksi)="row">
          <b-button
            class="mx-1"
            variant="danger-2"
            @click="destroyPatient(row.item.id)"
          >
            <i class="ri-delete-bin-6-line mx-2" />
            Hapus
          </b-button>
          <router-link :to="`/dashboards/edit-patient/${row.item.id}`">
            <b-button class="mx-1" variant="info-1">
              <i class="ri-edit-box-line mx-2" />
              Edit
            </b-button>
          </router-link>
        </template>
        <template #table-busy>
          <div class="hp-p1-body text-center my-2">
            <b-spinner class="align-middle mr-8" />
            <strong>Memuat...</strong>
          </div>
        </template>
      </b-table>
      <b-pagination
        class="mb-3"
        align="right"
        @input="changePage"
        v-model="table.currentPage"
        :per-page="table.perPage"
        :total-rows="table.total"
      />
    </div>
  </b-row>
</template>

<script>
import {
  BButton, BButtonGroup, BFormInput, BInputGroup, BInputGroupPrepend, BCol, BRow, BSpinner, BTable, BPagination, BFormSelect
} from 'bootstrap-vue'
import tableDataMixins from '../../../../mixins/tableDataMixins'

export default {
  name: 'listpatients',
  data() {
    return {
      table: {
        items: [
          {
            no: "",
            name: "",
            id_patient: "",
            birth_date: "",
            blood_id: "",
            updated_at: "",
            created_at: "",
          },
        ],
      },
      fields: [
        {
          label: "NO",
          key: "no",
          sortable: false,
        },
        {
          label: "Nama Pasien",
          key: "name",
          sortable: true,
        },
        {
          label: "Id Pasien",
          key: "id_patient",
          sortable: true,
        },
        {
          label: "Tanggal Lahir",
          key: "birth_date",
          sortable: true,
        },
        {
          label: "Gol Darah",
          key: "blood_id",
          sortable: true,
        },
        {
          label: "Update Terakhir",
          key: "updated_at",
          sortable: true,
        },
        {
          key: "Aksi",
          sortable: false,
        },
      ],
      dayOptions: Array.from({ length: 31 }, (_, i) => ({
        value: i + 1,
        text: i + 1,
      })),
      monthOptions: Array.from({ length: 12 }, (_, i) => ({
        value: i + 1,
        text: i + 1,
      })),
      yearOptions: Array.from({ length: 15 }, (_, i) => ({
        value: 2010 + i,
        text: 2010 + i,
      })),
    };
  },
  components: {
    BSpinner,
    BButtonGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BTable,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
  },
  mixins: [tableDataMixins],
  computed: {
    patients() {
      return this.$store.state.patientStore.patients;
    },
    totaPages() {
      return this.$store.state.patientStore.totalPages;
    },
    isBusy() {
      return this.$store.state.patientStore.isBusy;
    },
    itemsFormatted() {
      return this.table.items.map((item, index) => {
        const [datePart] = item.updated_at.split("T");
        const [year, month, day] = datePart.split("-");
        const formatDateLocal = `${day}-${month}-${year}`;
        const golDarahText = this.convertToText(item.blood_id);
        const slicedIdPatient = item.id_patient.slice(0, 5);
        return {
          no: index + 1,
          ...item,
          id_patient: slicedIdPatient,
          blood_id: golDarahText,
          updated_at: formatDateLocal,
        };
      });
    },
  },
  methods: {
    async fetchData() {
      this.table.loading = this.isBusy;
      await this.$store.dispatch("patientStore/getPatients", {
        page: this.table.currentPage,
        per_page: this.table.perPage,
        search: this.table.search,
        year: this.table.year,
        month: this.table.month,
        day: this.table.day,
      });
      this.table.items = this.patients;
      this.table.total = this.totaPages;
      console.log(this.itemsFormatted, "ffff");
    },
    async destroyPatient(id) {
      let formData = new FormData();
      formData.append("id", id);

      await this.$store
        .dispatch("patientStore/destroyPatient", formData)
        .then(() => {
          this.$bvToast.toast("Data Pasien Berhasil Di Hapus", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        });

      await this.$store.dispatch("patientStore/getPatients");
    },
    filterData() {
      this.table.items = this.table.items.filter((item) => {
        const date = new Date(item.created_at);
        const day =
          this.table.day !== null ? date.getDate() === this.table.day : true;
        const month =
          this.table.month !== null
            ? date.getMonth() + 1 === this.table.month
            : true;
        const year =
          this.table.year !== null
            ? date.getFullYear() === this.table.year
            : true;
        return day && month && year;
      });
      this.fetchData();
    },
    convertToText(golDarahValue) {
      switch (golDarahValue) {
        case 1:
          return "A+";
        case 2:
          return "A-";
        case 3:
          return "B+";
        case 4:
          return "B-";
        case 5:
          return "AB+";
        case 6:
          return "AB-";
        case 7:
          return "O";
        default:
          return "";
      }
    },
    changePage(page) {
      this.table.currentPage = page;
      this.fetchData();
    },
    debounceSearch() {
      clearTimeout(this.table.debounceTimer);
      this.table.debounceSearch = setTimeout(() => {
        this.onChangeSearchTable();
      }, 500);
    },
    onChangeSearchTable() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped></style>
